<template>
    <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-btn
            class="pa-0 ml-4"
            :class="{'primary--text':!hover}"
            rounded
            :elevation="hover ? 0 : 6"
            :color="hover ? 'primary':'greyLight'"
            @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
            Volver
        </v-btn>
    </v-hover>
</template>

<script>
export default {
    name: 'btn-volver',
    // props: {},
    data: function(){
        return {}
    },
    // computed: {},
    //methods: {}
    // watch: {},
    // components: {},
    // mixins: [],
    // filters: {},
    // -- Lifecycle Methods
    // -- End Lifecycle Methods
}
</script>

<style scoped>
    
</style>