<template>
  <v-container fluid class="ma-0 px-4">
    <v-row>
      <v-col
        md="6"
        offset-lg="3"
        offset-md="3"
        offset-sm="0"
        lg="6"
        sm="12"
        class="text-center pa-5 d-flex flex-column justify-center"
      >
        <base-material-card color="info" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light text-center">
              Ingresa al panel de administración
            </div>
          </template>
          <v-card-text>
            <v-img
              src="/images/logo-nodriza-negro.png"
              class="text-center"
              style="margin: auto"
              width="50%"
            >
            </v-img>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              autocomplete="false"
            >
              <v-text-field
                v-model="email"
                label="E-mail"
                :rules="rules.emailRules"
                required
                placeholder="E-mail"
                clearable
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                label="Contraseña"
                placeholder="Contraseña"
                :rules="rules.passwordRules"
                required
                clearable
                outlined
                dense
              >
              </v-text-field>
              <div v-if="errors">
                <p class="error--text">Usuario y/o contraseña incorrecta</p>
              </div>
              <div class="d-flex justify-space-between">
                <btn-volver />

                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-btn
                    :elevation="hover ? 0 : 6"
                    :color="hover ? 'secondaryDark' : 'secondary'"
                    rounded
                    class="text-center ma-0"
                    :disabled="!valid || dialog"
                    @click="login()"
                    @keyup.enter="login"
                  >
                    Ingresar
                  </v-btn>
                </v-hover>
              </div>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="greyDark" dark>
        <v-card-text class="white--text">
          Ingresando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import API from "@/api";
import { Configuration } from "@/configuration";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import { EventBus } from "@/main.js";
import BtnVolver from "@/components/BtnVolver.vue";

export default {
  title: "Admin login - Onestock",
  name: "Login",
  data: () => ({
    alert: false,
    dialog: false,
    errors: false,
    valid: true,
    email: "",
    showPassword: false,
    password: "",
    rules: {
      emailRules: [
        (v) => !!v || "El e-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "Ingrese un e-mail válido",
      ],
      passwordRules: [(v) => !!v || "La contraseña es requerida"],
    },
  }),
  computed: {},
  methods: {
    ...mapActions([
      "RegisterToken",
      "RegisterexpireDate",
      "RegisterUser",
      "RegisterTipeUser",
    ]),
    login() {
      EventBus.$emit("cargando", true);
      this.$data.errors = false;
      this.$data.dialog = true;
      if (this.$refs.form.validate()) {
        API.loginAdmin(this.$data.email, this.$data.password)
          .then((res) => {
            this.RegisterToken(res.data.token.token);
            this.RegisterexpireDate(res.data.token.expires_token);
            this.RegisterTipeUser("admin");
            API.verAdmin(res.data.token.token)
              .then((res) => {
                this.RegisterUser(res.data.respuesta);
                this.$data.dialog = false;
                setTimeout(() => {
                  if (res.data.respuesta) {
                    EventBus.$emit("login", "Haz iniciado la sesión");
                  }
                  this.$router.push({
                    path: "/admin/productos",
                  });
                }, Configuration.redirect.timeout);
              })
              .catch((err) => {
                console.log(err);
                EventBus.$emit("cargando", false);
              });
          })
          .catch(() => {
            this.$data.dialog = false;
            this.$data.errors = true;
            EventBus.$emit("cargando", false);
          });
      } else {
        this.$data.dialog = false;
      }
    },
  },
  components: {
    BtnVolver,
  },
};
</script>
