import { render, staticRenderFns } from "./BtnVolver.vue?vue&type=template&id=be4a4694&scoped=true&"
import script from "./BtnVolver.vue?vue&type=script&lang=js&"
export * from "./BtnVolver.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be4a4694",
  null
  
)

export default component.exports